<template>
  <div class="main__pages main__pages--center">
    <div class="main__pages--404">
      <div>
        <img src="../../public/404.jpg" alt="404 Error" />
        <h1>Ошибка 404</h1>
        <h2>Страница по данному адресу была перенесена или не существует.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  created() {
    // Смена активной страницы в хранилище при загрузке
    this.CHANGE_ACTIVE_PAGE("404");
  },

  methods: {
    ...mapActions(["CHANGE_ACTIVE_PAGE"])
  }
};
</script>

<style lang="scss" scoped>
.main__pages--center {
  @include flex;

  .main__pages--404 {
    @include flex;
    height: 700px;
    text-align: center;
    width: 700px;

    img {
      margin-bottom: -70px;
    }

    h1,
    h2 {
      margin: 10px 0;
    }

    h1 {
      color: $color-red;
    }
  }
}
</style>
